import PropTypes from 'prop-types';
import * as React from 'react';
import Footer from './Footer';
import Header from './Header/Header';

const Layout = ({ transparentHeader, children }) => (
  <>
    <Header transparentHeader={transparentHeader} />
    {children}
    <Footer />
  </>
);

Layout.propTypes = {
  transparentHeader: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default Layout;
