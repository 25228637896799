import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const SiteLink = ({
  url, text, isLinkInternal, className, leftIcon,
}) => (
  isLinkInternal ? (
    <Link to={url} className={className}>
      {leftIcon}
      {leftIcon ? <span className="header-link">{text}</span> : text}
    </Link>
  ) : (
    <a href={url} className={className}>
      {text}
    </a>
  )
);

SiteLink.propTypes = {
  url: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  isLinkInternal: PropTypes.bool.isRequired,
  className: PropTypes.string,
  leftIcon: PropTypes.element,
};

SiteLink.defaultProps = {
  className: '',
  leftIcon: null,
};

export default SiteLink;
