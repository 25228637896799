import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import MobileMenu from './MobileMenu';
import SiteLink from '../shared/SiteLink';

const MobileNav = ({
  isOpen, links, headerLogo, setIsOpen, logoLinkText, logoLinkUrl,
}) => {
  const className = `${isOpen ? 'transition-transform duration-500 ease-out translate-x-0' : 'transition-transform duration-500 ease-in -translate-x-full'}`;
  return (
    <div className={`w-full top-0 transform fixed h-screen z-10 bg-cream mb-24 xl:hidden ${className}`}>
      <div className="flex items-center justify-between px-6 pt-6 md:px-[30px] md:pt-[30px] lg:px-[50px]">
        <MobileMenu isOpen={isOpen} setIsOpen={setIsOpen} />
        <Link to={logoLinkUrl}>
          <img alt={headerLogo.alt} src={headerLogo.url} width={39} height={32} />
        </Link>
        <SiteLink
          url="/login"
          text="Log in"
          isLinkInternal={false}
          className="block text-s-text-s hover:underline"
        />
      </div>
      <div className="flex flex-col items-center px-6 pt-24 gap-[20px]">
        <div key={`mobile-header-link-${logoLinkUrl}`}>
          <SiteLink
            isLinkInternal
            url={logoLinkUrl}
            text={logoLinkText}
            className="text-s-display-xxl font-larken hover:underline"
          />
        </div>
        {links.map((link) => (
          <div key={`mobile-header-link-${link.primary.url.document?.url}`}>
            <SiteLink
              url={link.primary.url.document?.url}
              text={link.primary.name.text}
              isLinkInternal={link.primary.is_link_internal}
              className="text-s-display-xxl font-larken hover:underline"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

MobileNav.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  links: PropTypes.arrayOf(PropTypes.object).isRequired,
  headerLogo: PropTypes.shape({
    alt: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
  setIsOpen: PropTypes.func.isRequired,
  logoLinkText: PropTypes.string.isRequired,
  logoLinkUrl: PropTypes.string.isRequired,
};

export default MobileNav;
