// eslint-disable-next-line import/no-unresolved
import { useLocation } from '@reach/router';

const convertToCategories = (items) => items.reduce((prev, curr) => {
  const category = curr.footer_link_category.text;

  let existingItems = [];
  if (category in prev) {
    existingItems = prev[category];
  }

  existingItems.push({
    url: curr.footer_link_url.document?.url,
    title: curr.footer_link_text.text,
  });

  const updatedNavItems = prev;
  updatedNavItems[category] = existingItems;
  return updatedNavItems;
}, {});

/**
 * Loops through an array of "sibling" pages, and returns the index of the one which
 * matches the current URL.
 *
 * @param {*} siblings
 * @returns index of current page, or -1 if not found
 */
const findCurrentPageIndexFromSiblings = (siblings) => {
  const { pathname } = useLocation();
  return siblings.findIndex((el) => el.url === pathname);
};

const utils = {
  convertToCategories,
  findCurrentPageIndexFromSiblings,
};

export default utils;
