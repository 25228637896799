import React, { useState } from 'react';
import {
  graphql, useStaticQuery, navigate, Link,
} from 'gatsby';
import PropTypes from 'prop-types';

import MobileMenu from './MobileMenu';
import MobileNav from './MobileNav';
import SiteLink from '../shared/SiteLink';
import NavDropdown from '../shared/NavDropdown/NavDropdown';

import navDropdownConstants from '../shared/NavDropdown/navDropdown.constants';
import windowService from '../../services/window.service';

const { navDropdownTypes } = navDropdownConstants;

const Header = ({ transparentHeader }) => {
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

  const queryData = useStaticQuery(graphql`{
    navItems: prismicHeader {
      data {
        body {
          ... on PrismicHeaderDataBodyDropdownLinks {
            primary {
              name {
                text
              }
              url {
                id
                document {
                  ... on PrismicPage {
                    id
                    url
                  }
                }
              }
              is_visible_on_mobile
              is_link_internal
              is_button
            }
          }
        }
        logo {
          url
        }
        logo_link_text {
          text
        }
        logo_link_url {
          document {
            ... on PrismicPage {
              id
              url
            }
          }
        }
        logo_small {
          url
        }
      }
    }
    allPrismicPage(
      sort: {order: ASC, fields: data___order}
    ) {
      nodes {
        url
        uid
        prismicId
        data {
          parent {
            id
          }
          title {
            text
          }
        }
      }
    }
  }
`);

  const bodyData = queryData.navItems.data;
  const { logo_link_url: logoLinkUrl, logo_link_text: logoLinkText, body: links } = bodyData;
  const { allPrismicPage } = queryData;

  // Go through our list of top level links and find all the siblings for those links,
  // adding them to the list so they can be rendered as dropdown.
  const linksWithSiblings = links.map((link) => {
    // First find out if this link has a parentID.
    // We need that to find siblings and make a dropdown.
    const thisPage = allPrismicPage.nodes.find((page) => {
      if (!page.data.parent.id) {
        return false;
      }

      return page.prismicId === link.primary.url.id;
    });

    const siblings = thisPage ? allPrismicPage.nodes.filter((page) => {
      // This page doesn't have a parent, so can't be siblings with anyone.
      if (!page.data.parent.id) {
        return false;
      }

      // It is a sibling if has the same parent
      return page.data.parent.id === thisPage.data.parent.id;
    }) : [];

    // Now add all of the children we found to this link and return it to our enriched list.
    const linkWithSiblings = link;
    linkWithSiblings.siblings = siblings;
    return linkWithSiblings;
  });

  const hasNestedLinks = (siblings) => (
    !!siblings.length
  );

  const renderHeaderButton = (link) => (
    <button
      key={`header-button-link-${link.primary.url.document?.url}`}
      type="button"
      className={`header-button px-[16px] py-[10px] rounded text-button text-black bg-transparent ${link.primary.is_visible_on_mobile ? 'block' : 'hidden'} xl:block`}
      onClick={() => (
        link.primary.is_link_internal
          ? navigate(link.primary.url.document?.url)
          : windowService.openExternalUrl(link.primary.url.document?.url))}
    >
      {link.primary.name.text}
    </button>
  );

  const renderNavigationItem = (link) => (hasNestedLinks(link.siblings)
    ? (
      <div className="hidden xl:block">
        <NavDropdown
          activeLinkText={link.primary.name.text}
          links={link.siblings}
          type={navDropdownTypes.DESKTOP}
        />
      </div>
    ) : (
      <SiteLink
        url={link.primary.url.document?.url}
        text={link.primary.name.text}
        isLinkInternal={link.primary.is_link_internal}
        className={`header-link text-m-text-s ${link.primary.is_visible_on_mobile ? 'block' : 'hidden'} xl:block`}
      />
    ));

  const renderHeaderLink = (link) => (
    link.primary.is_button ? renderHeaderButton(link) : renderNavigationItem(link)
  );

  return (
    <nav className={transparentHeader ? '' : 'bg-dark-cream'}>
      <div className="max-w-screen-2xl mx-auto">
        <div className="px-6 pt-6 md:px-[30px] md:pt-[30px] lg:px-[50px] 2xl:px-[110px]">
          <div className="flex items-center justify-between">
            <MobileMenu isOpen={isMobileNavOpen} setIsOpen={setIsMobileNavOpen} />
            <div>
              <div className="md:max-w-[180px] 2xl:max-w-[228px]">
                <Link to={logoLinkUrl.document?.url}>
                  <img
                    className="hidden md:block"
                    alt={bodyData.logo.alt}
                    src={bodyData.logo.url}
                  />
                </Link>
              </div>
              <Link to={logoLinkUrl.document?.url}>
                <img
                  width={39}
                  height={32}
                  className="md:hidden"
                  alt={bodyData.logo_small.alt}
                  src={bodyData.logo_small.url}
                />
              </Link>
            </div>
            <div className="flex items-center xl:gap-8">
              {linksWithSiblings.map(renderHeaderLink)}
            </div>
          </div>
        </div>
      </div>
      <MobileNav
        isOpen={isMobileNavOpen}
        links={links}
        headerLogo={bodyData.logo_small}
        logoLinkText={logoLinkText.text}
        logoLinkUrl={logoLinkUrl.document?.url}
        setIsOpen={setIsMobileNavOpen}
      />
    </nav>
  );
};

Header.propTypes = {
  transparentHeader: PropTypes.bool.isRequired,
};

export default Header;
