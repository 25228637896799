import React from 'react';
import PropTypes from 'prop-types';

const Divider = ({ className }) => (
  <div className={className}>
    <div className="w-full border-light-gray border-[1px] border-solid" />
  </div>
);

Divider.propTypes = {
  className: PropTypes.string,
};

Divider.defaultProps = {
  className: '',
};

export default Divider;
