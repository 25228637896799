import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Divider from './shared/Divider';
import SiteLink from './shared/SiteLink';

import utils from '../utils/utils';

import Logo from '../assets/logo.svg';

const renderNavItems = (items) => items.map(({ url, title }) => {
  const key = `footer-item-${title}-${url}`;
  return (
    <li key={key} className="mb-4 last:mb-0 text-m-text-xs">
      <SiteLink isLinkInternal url={url} text={title} />
    </li>
  );
});

const renderNavCategories = (categories) => Object.keys(categories).map((category) => {
  const items = categories[category];
  const key = `footer-category-${category}`;

  return (
    <div key={key}>
      <p className="font-medium mb-4 text-m-text-xs">{category}</p>
      <ul className="mb-12 md:mb-16">
        {renderNavItems(items)}
      </ul>
    </div>
  );
});

const Footer = () => {
  const queryData = useStaticQuery(graphql`
    {
      prismicFooter {
        data {
          body {
            ... on PrismicFooterDataBodyFooter {
              id
              primary {
                footer_privacy_link {
                  document {
                    ... on PrismicPage {
                      id
                      url
                    }
                  }
                }
                footer_terms_link {
                  document {
                    ... on PrismicPage {
                      id
                      url
                    }
                  }
                }
              }
              items {
                footer_link_category {
                  text
                }
                footer_link_text {
                  text
                }
                footer_link_url {
                  document {
                    ... on PrismicPage {
                      id
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const dataBody = queryData.prismicFooter.data.body[0];
  const navCategories = utils.convertToCategories(dataBody.items);
  const { footer_terms_link: termsLink, footer_privacy_link: privacyLink } = dataBody.primary;

  return (
    <footer className="px-6 mb-16 md:px-[30px] xl:px-[50px] 2xl:px-[110px] 2xl:max-w-screen-2xl mx-auto">
      <div>
        <div className="mb-[42px] xl:hidden">
          <img src={Logo} alt="Footer logo" />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 2xl:grid-cols-5">
          <div className="hidden xl:block mb-[42px]">
            <img src={Logo} alt="Footer logo" />
          </div>
          {renderNavCategories(navCategories)}
        </div>
      </div>
      <Divider className="mb-4" />
      <div className="mb-16 flex flex-row items-center justify-between font-medium text-m-text-xs 2xl:text-l-text-xs">
        <div>
          <span>
            ©
            {' '}
            {new Date().getFullYear()}
            {' '}
            Yearbook Machine Limited
          </span>
        </div>
        <div>
          <span>
            <SiteLink
              isLinkInternal
              url={termsLink.document?.url}
              text="Terms"
            />
          </span>
          <span> - </span>
          <span>
            <SiteLink
              isLinkInternal
              url={privacyLink.document?.url}
              text="Privacy"
            />
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
