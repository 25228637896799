import * as React from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon, ChevronLeftIcon } from '@heroicons/react/solid';
import PropTypes from 'prop-types';

import SiteLink from '../SiteLink';

import navDropdownConstants from './navDropdown.constants';

const { navDropdownTypes } = navDropdownConstants;

const NavDropdown = ({
  activeLinkText, activeLinkUrl, links, type,
}) => {
  const isMobileDropdown = type === navDropdownTypes.MOBILE;

  const getLinkData = (link) => ({
    url: link?.url,
    text: link?.data?.title.text,
    isLinkInternal: true,
  });

  const renderMobileDropdown = () => (
    activeLinkUrl
      ? (
        <div className="flex items-center">
          <SiteLink
            isLinkInternal
            url={activeLinkUrl}
            text={activeLinkText}
            leftIcon={<ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />}
            className="flex items-center"
          />
        </div>
      ) : (
        <Menu>
          {({ open }) => (
            <>
              <Menu.Button className="flex items-center focus:outline-none">
                {activeLinkText}
                {open ? <ChevronUpIcon className="h-5 w-5" aria-hidden="true" /> : <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />}
              </Menu.Button>
              <Transition show={open}>
                <Menu.Items
                  static
                  className="focus:outline-none absolute whitespace-nowrap origin-top-right bg-white shadow-lg border rounded-lg z-10"
                >
                  <div className="py-2">
                    {links.map((link) => {
                      const { url, text, isLinkInternal } = getLinkData(link);
                      return (
                        <Menu.Item>
                          {({ active }) => (
                            <div>
                              <SiteLink
                                url={url}
                                text={text}
                                isLinkInternal={isLinkInternal}
                                className={`${
                                  active
                                    ? 'link-hover-background text-white'
                                    : ''
                                } flex justify-between w-full px-4 py-[6px] text-s-text-m`}
                              />
                            </div>
                          )}
                        </Menu.Item>
                      );
                    })}
                  </div>
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu>
      )
  );

  const renderDesktopDropdown = () => (
    <div className="desktop-dropdown inline-block relative text-m-text-s">
      <button type="button" className="flex items-center focus:outline-none">
        {activeLinkText}
        <ChevronDownIcon className="chevron h-5 w-5" aria-hidden="true" />
      </button>
      <div className="desktop-dropdown-menu hidden focus:outline-none absolute whitespace-nowrap origin-top-right bg-white shadow-lg border rounded-lg z-10">
        <div className="py-2">
          {links.map((link) => {
            const { url, text, isLinkInternal } = getLinkData(link);
            return (
              <div>
                <SiteLink
                  url={url}
                  text={text}
                  isLinkInternal={isLinkInternal}
                  className="desktop-link-hover-background flex justify-between w-full px-4 py-[6px] text-m-text-s"
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );

  return (
    <div className="relative inline-block text-left">
      {isMobileDropdown ? renderMobileDropdown() : renderDesktopDropdown()}
    </div>
  );
};

NavDropdown.propTypes = {
  activeLinkText: PropTypes.string.isRequired,
  activeLinkUrl: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(PropTypes.shape({
    nav_hero_link_text: PropTypes.shape({
      text: PropTypes.string,
    }),
    nav_hero_link_url: PropTypes.shape({
      url: PropTypes.string,
    }),
  })).isRequired,
  type: PropTypes.string,
};

NavDropdown.defaultProps = {
  type: navDropdownTypes.MOBILE,
};

export default NavDropdown;
