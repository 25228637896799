import React from 'react';
import PropTypes from 'prop-types';

import HamburgerIcon from '../../assets/hamburger.svg';
import CloseIcon from '../../assets/close.svg';

const MobileMenu = ({ isOpen, setIsOpen }) => (
  <div className="flex xl:hidden">
    <button
      type="button"
      className="inline-flex items-center justify-center focus:outline-none"
      aria-controls="mobile-menu"
      aria-expanded="false"
      onClick={() => setIsOpen(!isOpen)}
    >
      {!isOpen ? (
        <img src={HamburgerIcon} alt="Hamburger icon" />
      ) : (
        <img src={CloseIcon} alt="Close icon" />
      )}
    </button>
  </div>
);

MobileMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

export default MobileMenu;
